import React, { ReactElement, useMemo } from 'react'
import styles from './Header.module.scss'
import LogoBar from './LogoBar/LogoBar'
import Menu from './Menu/Menu'
import MenuOptions from './MenuOptions'
import UserMenu from './UserMenu/UserMenu'
import { useLocation } from 'react-router-dom'
import PredictionButton from './PredictionButton/PredictionButton'

const Header = (): ReactElement => {
    let location = useLocation()
    const showButton = useMemo(
        () => location.pathname === '/management_status',
        [location]
    )

    return (
        <header className={styles.header}>
            <div className={styles.header_items_left}>
                <Menu menuItems={MenuOptions} />
                {showButton && <PredictionButton />}
            </div>
            <div className={styles.header_items_right}>
                <UserMenu />
                <LogoBar logoType="management_status" />
            </div>
        </header>
    )
}

export default Header
