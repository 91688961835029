import React, { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import NotFound from '../pages/NotFound/NotFound'
import { withSuspenseAndErrorBoundary } from './routeUtils'
import PrivateRoute from './PrivateRoute'
import Layout from 'components/Layout/Layout '
import ErrorPage from 'pages/ErrorPage/ErrorPage'

// Lazy-loaded page components
const Login = lazy(() => import('../pages/Login/Login'))
const Dashboard = React.lazy(() => import('../pages/Dashboard/Dashboard'))
const ManagementStatusDashboard = React.lazy(
    () => import('../pages/ManagementStatusDashboard/ManagementStatusDashboard')
)
const ScrubChart = React.lazy(() => import('../pages/ScrubChart/ScrubChart'))
const ScrubChartNew = React.lazy(
    () => import('../pages/ScrubChartNew/ScrubChartNew')
)
const OperatingRoomsStatus = React.lazy(
    () => import('../pages/OperatingRoomsStatus/OperatingRoomsStatus')
)
const EDLive = React.lazy(() => import('../pages/EDLive/EDLive'))
const OperatingRoom = React.lazy(
    () => import('../pages/OperatingRoom/OperatingRoom')
)
const CriticalCare = React.lazy(
    () => import('../pages/CriticalCare/CriticalCare')
)
const WardView = React.lazy(() => import('../pages/WardView/WardView'))

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />,
        // errorElement: <NotFound />,
    },
    {
        element: <Layout />,
        children: [
            {
                path: '/ward-v2/:department',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(WardView)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/ward/:department',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(Dashboard)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/management_status',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(
                            ManagementStatusDashboard
                        )}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        <Navigate replace to="/default" />
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/ScrubChart',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(ScrubChart)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/ScrubChartNew',
                element: (
                    <PrivateRoute allowedRoles={['test']}>
                        {withSuspenseAndErrorBoundary(ScrubChartNew)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/operating_rooms_status',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(OperatingRoomsStatus)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/ed_live',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(EDLive)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/operating-room',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(OperatingRoom)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/critical-care',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {withSuspenseAndErrorBoundary(CriticalCare)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/*',
                element: (
                    <PrivateRoute allowedRoles={['admin']}>
                        {/* <NotFound /> */}
                        {withSuspenseAndErrorBoundary(NotFound)}
                    </PrivateRoute>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },
])

export default router
