import { useEffect, useState } from 'react'
import {
    useGeInflowQuery,
    useGetDepartmentsQuery,
} from '../redux/rtk/departmentTabs/departmentTabsSlice'
import { Inflow } from '../redux/rtk/departmentTabs/types'
import { useSkipCondition } from 'utils/helpers/skipCondition'

type MergedDataProps = {
    inflow: Inflow[]
    predictions?: Inflow[]
    hasAlerts: boolean
    name: string
    capacity: number
    maxCapacity: number
    currentCountOfPatients: number
    sortOrder: number
}[]

export default function useCombinedData() {
    const [combinedData, setCombinedData] = useState<MergedDataProps>([])

    const { data: departmentsData } = useGetDepartmentsQuery(
        {},
        { skip: useSkipCondition() }
    )

    const { data: inflowData } = useGeInflowQuery(
        {},
        { skip: useSkipCondition() }
    )

    useEffect(() => {
        if (departmentsData && inflowData) {
            const mergedData = departmentsData?.departments?.map(
                (department) => {
                    const inflowInfo = inflowData?.departments?.find(
                        (inflow) => inflow.departmentName === department.name
                    )
                    return {
                        ...department,
                        inflow: inflowInfo ? inflowInfo.inflow : [],
                        // predictions: inflowInfo ? inflowInfo.predictions : [],
                    }
                }
            )
            setCombinedData(mergedData)
        }
    }, [departmentsData, inflowData])

    return combinedData
}
