import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    DischargeResponseData,
    LabsDataResponse,
    MedicalUnitType,
    TranslationResponse,
    WardViewData,
    WardViewQueryParams,
    Option,
    UpdateDischargeFactors,
} from './types'
import { backendApiUrl } from 'utils/API'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import {
    DISCHARGE_FACTORS_MOCK,
    LABS_MOCK_DATA,
    TRANSLATION_MOCK,
    WARD_MSISU_MOCK,
    WARD_PATIENTS_MOCK,
    WARD_SSU_MOCK,
} from 'utils/__Mocks__/wardViewMock'
import qs from 'qs'
import {
    clearWardFilters,
    setDoctorNamesList,
} from '../management/managemenSlice'
import { toast, ToastOptions } from 'react-toastify'

const mock_map = {
    MSICU: WARD_MSISU_MOCK,
    SSU: WARD_SSU_MOCK,
    Cardiology: WARD_PATIENTS_MOCK,
}
const toastOptions: ToastOptions = {
    position: 'bottom-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
}

export const wardViewApi = createApi({
    reducerPath: 'wardViewApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendApiUrl}/api/v1/Wards`,
        prepareHeaders,
    }),

    tagTypes: ['wardViewData', 'DischargeFactors'],

    endpoints: (builder) => ({
        getWardViewData: builder.query<WardViewData, WardViewQueryParams>({
            // providesTags: ['wardViewData'],
            //@ts-ignore
            queryFn: createQueryFn(
                ({
                    wardSelection,
                    page,
                    size,
                }: {
                    wardSelection: string
                    page?: number
                    size?: number
                }) => {
                    const queryParams = {
                        WardSelection: wardSelection,
                        Page: page,
                        Size: size,
                    }

                    // Build the query string, skipping null/undefined values
                    const queryString = qs.stringify(queryParams, {
                        skipNulls: true,
                    })
                    return `${backendApiUrl}/api/v1/Wards?${queryString}`
                },
                () => WARD_PATIENTS_MOCK
            ),
            providesTags: (result) =>
                result
                    ? [
                          ...result?.wards?.map(({ id }) => ({
                              type: 'wardViewData' as const,
                              id,
                          })),
                          { type: 'wardViewData', id: 'LIST' },
                      ]
                    : [{ type: 'wardViewData', id: 'LIST' }],
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    // Wait for the query to complete
                    const { data } = await queryFulfilled

                    // Extract unique visitFollowingDoctorName values from the wards data
                    const doctorNames = data?.wards?.map(
                        (ward) => ward?.visitFollowingDoctorName
                    )
                    const uniqueDoctorNames = Array?.from(new Set(doctorNames))
                    // Dispatch an action to save the unique doctor names in the state
                    dispatch(setDoctorNamesList(uniqueDoctorNames))
                } catch (error) {
                    console.error('Error fetching ward data:', error)
                }
            },
        }),
        updateCommunityInvolvement: builder.mutation<
            void,
            { wardId: number; involved: boolean | null }
        >({
            query: ({ wardId, involved }) => ({
                url: `${wardId}/community-involved`,
                method: 'POST',
                body: { involved },
            }),
            // invalidatesTags: ['wardViewData'],
            invalidatesTags: (result, error) => (error ? [] : ['wardViewData']),
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `Community involvement for MRN: ${arg.wardId} was updated successfully!`,
                        toastOptions
                    )
                } catch (error) {
                    toast.error(
                        `Failed to update community involvement for MRN: ${arg.wardId}!`,
                        toastOptions
                    )
                }
            },
        }),
        getLabsResultData: builder.query<LabsDataResponse, number>({
            //@ts-ignore
            queryFn: createQueryFn(
                (visitId) => {
                    return `${backendApiUrl}/api/v1/Wards/${visitId}/labs`
                },
                () => LABS_MOCK_DATA
            ),
        }),
        markLabsAsRead: builder.mutation<
            void,
            { wardId: number; labIds: string[] }
        >({
            query: ({ wardId, labIds }) => ({
                url: `${wardId}/labs/mark-as-read`,
                method: 'POST',
                body: { labIds: labIds },
            }),
            // invalidatesTags: ['wardViewData'],
            invalidatesTags: (result, error) => (error ? [] : ['wardViewData']),
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `Mark labs as read for MRN: ${arg.wardId} was successfully!`,
                        toastOptions
                    )
                } catch (error) {
                    toast.error(
                        `Failed to mark labs as read for MRN: ${arg.wardId}!`,
                        toastOptions
                    )
                }
            },
        }),
        getTranslationsData: builder.query<TranslationResponse, void>({
            //@ts-ignore
            queryFn: createQueryFn(
                (visitId) => {
                    return `${backendApiUrl}/api/v1/Translations`
                },
                () => TRANSLATION_MOCK
            ),
        }),
        getDischargeFactors: builder.query<DischargeResponseData, number>({
            providesTags: (result, error, visitId) => [
                { type: 'DischargeFactors', id: `VISIT_${visitId}` },
            ],
            //@ts-ignore
            queryFn: createQueryFn(
                (visitId) => {
                    return `${backendApiUrl}/api/v1/Wards/${visitId}/discharge-factors`
                },
                () => DISCHARGE_FACTORS_MOCK
            ),
        }),
        updateDischargeFactors: builder.mutation<void, UpdateDischargeFactors>({
            query: ({ wardId, options, other }) => ({
                url: `${wardId}/discharge-factors`,
                method: 'POST',
                body: { wardId, options, other },
            }),
            // invalidatesTags: ['wardViewData'],
            invalidatesTags: (result, error) =>
                error ? [] : ['wardViewData', 'DischargeFactors'],
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    await queryFulfilled
                    toast.success(
                        `Discharge factors for MRN: ${arg.wardId} was updated successfully!`,
                        toastOptions
                    )
                } catch (error) {
                    toast.error(
                        `Failed to update discharge factors for MRN: ${arg.wardId}!`,
                        toastOptions
                    )
                }
            },
        }),
    }),
})

export const {
    useGetWardViewDataQuery,
    useGetLabsResultDataQuery,
    useGetTranslationsDataQuery,
    useUpdateCommunityInvolvementMutation,
    useGetDischargeFactorsQuery,
    useMarkLabsAsReadMutation,
    useUpdateDischargeFactorsMutation,
} = wardViewApi
