import { NsaCategories } from 'redux/rtk/nsa/types'
import { generateDate } from '../date/generateDate'

export const NEW_MSSS_NSA_MOCK = {
    categories: [
        {
            name: 'Var 08b - Evaluation in Progress',
            patientsCount: 15,
            outPatientsCount: 4,
            ccomtl: 53,
            hoursDelay: 7,
            hoursDelayPercent: 37,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 1,
                },
                {
                    facility: 'Hôpital Richardson',
                    count: 3,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 8,
                },
                {
                    date: generateDate(5),
                    count: 8,
                },
                {
                    date: generateDate(4),
                    count: 8,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 14,
                },
                {
                    date: generateDate(5),
                    count: 16,
                },
                {
                    date: generateDate(4),
                    count: 18,
                },
                {
                    date: generateDate(3),
                    count: 16,
                },
                {
                    date: generateDate(2),
                    count: 16,
                },
                {
                    date: generateDate(1),
                    count: 16,
                },
                {
                    date: generateDate(0),
                    count: 15,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 10,
                },
                {
                    date: generateDate(5),
                    count: 9,
                },
                {
                    date: generateDate(4),
                    count: 9,
                },
                {
                    date: generateDate(3),
                    count: 8,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 4,
                },
            ],
        },
        {
            name: 'Var 08c - Orientation Pending',
            patientsCount: 12,
            outPatientsCount: 2,
            ccomtl: 29,
            hoursDelay: 5,
            hoursDelayPercent: 36,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 2,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 5,
                },
                {
                    date: generateDate(4),
                    count: 5,
                },
                {
                    date: generateDate(3),
                    count: 5,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 5,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 14,
                },
                {
                    date: generateDate(5),
                    count: 8,
                },
                {
                    date: generateDate(4),
                    count: 12,
                },
                {
                    date: generateDate(3),
                    count: 15,
                },
                {
                    date: generateDate(2),
                    count: 14,
                },
                {
                    date: generateDate(1),
                    count: 14,
                },
                {
                    date: generateDate(0),
                    count: 12,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 1,
                },
                {
                    date: generateDate(5),
                    count: 1,
                },
                {
                    date: generateDate(4),
                    count: 1,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
        },
        {
            name: 'Var 09 - Home Services required',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 50,
            hoursDelay: 1,
            hoursDelayPercent: 17,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 3,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 1,
                },
                {
                    date: generateDate(0),
                    count: 1,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 11,
                },
                {
                    date: generateDate(5),
                    count: 11,
                },
                {
                    date: generateDate(4),
                    count: 10,
                },
                {
                    date: generateDate(3),
                    count: 10,
                },
                {
                    date: generateDate(2),
                    count: 8,
                },
                {
                    date: generateDate(1),
                    count: 6,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 10a - Intense rehab',
            patientsCount: 7,
            outPatientsCount: 0,
            ccomtl: 57,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 9,
                },
                {
                    date: generateDate(5),
                    count: 12,
                },
                {
                    date: generateDate(4),
                    count: 10,
                },
                {
                    date: generateDate(3),
                    count: 4,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 10b - Moderate Rehab',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 17,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 1,
                },
                {
                    date: generateDate(5),
                    count: 1,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 6,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 6,
                },
                {
                    date: generateDate(3),
                    count: 4,
                },
                {
                    date: generateDate(2),
                    count: 4,
                },
                {
                    date: generateDate(1),
                    count: 4,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 11 - Palliative',
            patientsCount: 2,
            outPatientsCount: 0,
            ccomtl: 50,
            hoursDelay: 2,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            inPatientsStatistics: [
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 4,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 4,
                },
            ],
            outPatientsStatistics: [
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 12a - Ressources de type Famille ou Intermediaire RTF/RI',
            patientsCount: 2,
            outPatientsCount: 0,
            ccomtl: 0,
            hoursDelay: 2,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 12b - Long term care (Not charged)',
            patientsCount: 23,
            outPatientsCount: 5,
            ccomtl: 64,
            hoursDelay: 19,
            hoursDelayPercent: 68,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 4,
                },
                {
                    facility: 'Hôpital Richardson',
                    count: 1,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 26,
                },
                {
                    date: generateDate(5),
                    count: 24,
                },
                {
                    date: generateDate(4),
                    count: 21,
                },
                {
                    date: generateDate(3),
                    count: 20,
                },
                {
                    date: generateDate(2),
                    count: 20,
                },
                {
                    date: generateDate(1),
                    count: 20,
                },
                {
                    date: generateDate(0),
                    count: 19,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 30,
                },
                {
                    date: generateDate(5),
                    count: 29,
                },
                {
                    date: generateDate(4),
                    count: 26,
                },
                {
                    date: generateDate(3),
                    count: 26,
                },
                {
                    date: generateDate(2),
                    count: 26,
                },
                {
                    date: generateDate(1),
                    count: 26,
                },
                {
                    date: generateDate(0),
                    count: 23,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 4,
                },
                {
                    date: generateDate(4),
                    count: 4,
                },
                {
                    date: generateDate(3),
                    count: 5,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
        },
        {
            name: 'Var 14 - Sante Mentale',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 33,
            hoursDelay: 6,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 7,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 7,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 15b - User awaiting housing order',
            patientsCount: 3,
            outPatientsCount: 0,
            ccomtl: 100,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 3,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 3,
                },
                {
                    date: generateDate(1),
                    count: 3,
                },
                {
                    date: generateDate(0),
                    count: 3,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Other',
            patientsCount: 1,
            outPatientsCount: 0,
            ccomtl: 0,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 1,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
    ],
}

export const NSA_CATEGORIES_MOCK: NsaCategories[] = [
    {
        nsaCategoryName: 'Other',
        inPatientsCount: 1,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 100,
        patientsWithDelayCount: 0,
        patientsWithDelayPercentage: 0,
        historyNsaDemographics: [
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(8),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 1,
                patientsWithDelayCount: 0,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 08b - Evaluation in Progress',
        inPatientsCount: 13,
        outPatientsCount: 12,
        patientsFromRegionPercentage: 44,
        patientsWithDelayCount: 9,
        patientsWithDelayPercentage: 36,
        historyNsaDemographics: [
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(8),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 0,
                patientsWithDelayCount: 9,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 0,
                patientsWithDelayCount: 9,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 9,
                patientsWithDelayCount: 11,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 16,
                outPatientsCount: 0,
                patientsWithDelayCount: 11,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 14,
                outPatientsCount: 11,
                patientsWithDelayCount: 8,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 14,
                outPatientsCount: 0,
                patientsWithDelayCount: 8,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 13,
                outPatientsCount: 0,
                patientsWithDelayCount: 9,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 13,
                outPatientsCount: 12,
                patientsWithDelayCount: 9,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [
            {
                origin_institution: 'CH Catherine Booth',
                count_origin: 6,
            },
            {
                origin_institution: 'Hôpital Richardson',
                count_origin: 7,
            },
        ],
        outDelayUnits: [
            {
                service_name: 'D7',
                count_service: 1,
            },
            {
                service_name: 'Geriatrics',
                count_service: 3,
            },
            {
                service_name: 'IM Teach',
                count_service: 1,
            },
            {
                service_name: 'Oncology',
                count_service: 2,
            },
            {
                service_name: 'Psychiatry',
                count_service: 1,
            },
            {
                service_name: 'SSU',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 08c - Orientation Pending',
        inPatientsCount: 12,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 66,
        patientsWithDelayCount: 3,
        patientsWithDelayPercentage: 25,
        historyNsaDemographics: [
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(8),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 0,
                patientsWithDelayCount: 4,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 11,
                outPatientsCount: 0,
                patientsWithDelayCount: 11,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 5,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 8,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 12,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 12,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'Oncology',
                count_service: 1,
            },
            {
                service_name: 'Psychiatry',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 09 - Home Services required',
        inPatientsCount: 8,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 62,
        patientsWithDelayCount: 3,
        patientsWithDelayPercentage: 37,
        historyNsaDemographics: [
            {
                inPatientsCount: 1,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(8),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 4,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 5,
                outPatientsCount: 0,
                patientsWithDelayCount: 4,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 4,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 8,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 8,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'Geriatrics',
                count_service: 1,
            },
            {
                service_name: 'SSU',
                count_service: 1,
            },
            {
                service_name: 'Surgery',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 10a - Intense rehab',
        inPatientsCount: 6,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 16,
        patientsWithDelayCount: 2,
        patientsWithDelayPercentage: 33,
        historyNsaDemographics: [
            {
                inPatientsCount: 6,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 6,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 6,
                outPatientsCount: 0,
                patientsWithDelayCount: 3,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 13,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 8,
                outPatientsCount: 0,
                patientsWithDelayCount: 5,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 4,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 6,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 6,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'IM Teach',
                count_service: 1,
            },
            {
                service_name: 'Surgery',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 10b - Moderate Rehab',
        inPatientsCount: 4,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 0,
        patientsWithDelayCount: 1,
        patientsWithDelayPercentage: 25,
        historyNsaDemographics: [
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'D7',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 11 - Palliative',
        inPatientsCount: 3,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 33,
        patientsWithDelayCount: 1,
        patientsWithDelayPercentage: 33,
        historyNsaDemographics: [
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 2,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 1,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'Palliative',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName:
            'Var 12a - Ressources de type Famille ou Intermediaire RTF/RI',
        inPatientsCount: 7,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 57,
        patientsWithDelayCount: 6,
        patientsWithDelayPercentage: 85,
        historyNsaDemographics: [
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 5,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 5,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 5,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 5,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'Cardiology',
                count_service: 1,
            },
            {
                service_name: 'Geriatrics',
                count_service: 3,
            },
            {
                service_name: 'Oncology',
                count_service: 1,
            },
            {
                service_name: 'Psychiatry',
                count_service: 1,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 12b - Long term care (Not charged)',
        inPatientsCount: 60,
        outPatientsCount: 2,
        patientsFromRegionPercentage: 46,
        patientsWithDelayCount: 45,
        patientsWithDelayPercentage: 72,
        historyNsaDemographics: [
            {
                inPatientsCount: 49,
                outPatientsCount: 0,
                patientsWithDelayCount: 44,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 49,
                outPatientsCount: 0,
                patientsWithDelayCount: 44,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 49,
                outPatientsCount: 3,
                patientsWithDelayCount: 44,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 51,
                outPatientsCount: 0,
                patientsWithDelayCount: 45,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 55,
                outPatientsCount: 2,
                patientsWithDelayCount: 45,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 58,
                outPatientsCount: 0,
                patientsWithDelayCount: 46,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 60,
                outPatientsCount: 0,
                patientsWithDelayCount: 45,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 60,
                outPatientsCount: 2,
                patientsWithDelayCount: 45,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [
            {
                origin_institution: 'CH Catherine Booth',
                count_origin: 1,
            },
            {
                origin_institution: 'Hôpital Richardson',
                count_origin: 1,
            },
        ],
        outDelayUnits: [
            {
                service_name: 'Cardiology',
                count_service: 2,
            },
            {
                service_name: 'D7',
                count_service: 3,
            },
            {
                service_name: 'Geriatrics',
                count_service: 28,
            },
            {
                service_name: 'Neurology',
                count_service: 6,
            },
            {
                service_name: 'Oncology',
                count_service: 2,
            },
            {
                service_name: 'SSU',
                count_service: 1,
            },
            {
                service_name: 'Surgery',
                count_service: 4,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 14 - Sante Mentale',
        inPatientsCount: 7,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 42,
        patientsWithDelayCount: 6,
        patientsWithDelayPercentage: 85,
        historyNsaDemographics: [
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 7,
                outPatientsCount: 0,
                patientsWithDelayCount: 6,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [
            {
                service_name: 'Geriatrics',
                count_service: 4,
            },
            {
                service_name: 'Psychiatry',
                count_service: 2,
            },
        ],
        lastModifiedDate: generateDate(0),
    },
    {
        nsaCategoryName: 'Var 15b - User awaiting housing order',
        inPatientsCount: 4,
        outPatientsCount: 0,
        patientsFromRegionPercentage: 50,
        patientsWithDelayCount: 0,
        patientsWithDelayPercentage: 0,
        historyNsaDemographics: [
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(7),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(6),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(5),
            },
            {
                inPatientsCount: 2,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(4),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(3),
            },
            {
                inPatientsCount: 3,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(2),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(1),
            },
            {
                inPatientsCount: 4,
                outPatientsCount: 0,
                patientsWithDelayCount: 0,
                computationDate: generateDate(0),
            },
        ],
        outPatientsOrigins: [],
        outDelayUnits: [],
        lastModifiedDate: generateDate(0),
    },
]
